// ** Constants for Action types
export const ARCHIVE = 'ARCHIVE'
export const GET_ALL_DATA = 'GET_ALL_DATA'
export const GET_DATA = 'GET_DATA'
export const GET_PROJECT = 'GET_PROJECT'
export const ADD_PROJECT = 'ADD_PROJECT'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const DELETE_PROJECT = 'DELETE_PROJECT'
export const RESTORE_PROJECT = 'RESTORE_PROJECT'
export const RESEST_PROJECT = 'RESEST_PROJECT'
