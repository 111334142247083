// ** Constants for Action types
export const REDIRECT = 'REDIRECT'
export const ARCHIVE = 'ARCHIVE'
export const GET_ALL_DATA = 'GET_ALL_DATA'
export const GET_DATA = 'GET_DATA'
export const GET_STUDENT = 'GET_STUDENT'
export const ADD_STUDENT = 'ADD_STUDENT'
export const UPDATE_STUDENT = 'UPDATE_STUDENT'
export const DELETE_STUDENT = 'DELETE_STUDENT'
export const RESTORE_STUDENT = 'RESTORE_STUDENT'
