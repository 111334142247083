// ** Constants for Action types
export const ARCHIVE = 'ARCHIVE'
export const GET_ALL_DATA = 'GET_ALL_DATA'
export const GET_DATA = 'GET_DATA'
export const GET_ATTENDANCE = 'GET_ATTENDANCE'
export const ADD_ATTENDANCE = 'ADD_ATTENDANCE'
export const UPDATE_ATTENDANCE = 'UPDATE_ATTENDANCE'
export const DELETE_ATTENDANCE = 'DELETE_ATTENDANCE'
export const RESTORE_ATTENDANCE = 'RESTORE_ATTENDANCE'
export const RESEST_ATTENDANCE = 'RESEST_ATTENDANCE'
export const GET_STUDENTS = 'GET_STUDENTS'
